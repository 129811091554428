import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "components/Layout/Layout";
import { CardContactUs } from "components/Design/Card";
import LocateUsSection from "components/KomodoLocateUs/LocateUsSection";
import { BannerCarousel } from "components/BannerCarousel";
import PageContext from "contexts/PageContext";
import { CRUISE_INQUIRY } from "store/constants/support";
import { generateSupportURL } from "utils/generateSupportURL";
import { transformObject } from "utils/transformv4";

const LocateUs = ({ data }) => {
  const { t } = useTranslation(["homepage"]);

  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    page: [
      {
        seo_tags,
        banners: [banner],
        sections,
      },
    ],
    sections: [destinations],
    hotelsByLocale: [hotelKomodo],
    hotelLakodia: [hotelLakodia],
    supportDrawer: [supportDrawer],
  } = transformObject(cms);

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotelLakodia,
  };

  const dataContact = {
    url: generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY),
    label: t("Contact Us"),
  };
  return (
    <PageContext.Provider value={{ showBannerPadding: true }}>
      <Layout
        destination={t("Komodo")}
        seoTitle={t("Ayana Komodo | Locate Us")}
        seoTags={seo_tags}
        banner={<BannerCarousel images={banner.images} />}
        showBanner={true}
        destinationCards={destinations.images}
        navigations={navigations}
        hotel={hotelLakodia}
        supportDrawer={supportDrawerData}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Lako di'a")}
        contactOnMobile={dataContact}
        contactOnDesktop={dataContact}>
        <LocateUsSection sections={sections} hotel={hotelKomodo} />
        <CardContactUs
          description={t("contactUsText")}
          label={t("Contact Us")}
          url={generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY)}
        />
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query LakodiaLocateUs($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      sections(filters: { name: { eq: "LKD_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(filters: { name: { eq: "LKD_Support_Drawer" } }) {
        ...NavSections
      }
      hotelsByLocale(language: $language, where: { hotel_code: "AKWB" }) {
        data {
          id
          attributes {
            ...NavHotel
            locate_hotels {
              data {
                id
                attributes {
                  name
                  by_air_notes
                  by_air {
                    id
                    from_location
                    airlines {
                      id
                      title
                      location_to_hotel
                      hotel_to_location
                    }
                  }
                }
              }
            }
          }
        }
      }
      hotelLakodia: hotelsByLocale(language: $language, where: { id: 5 }) {
        data {
          id
          attributes {
            ...NavHotel
          }
        }
      }
      page: pagesByLocale(
        language: $language
        where: { name: "Komodo_LocateUs" }
      ) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
            banners {
              ...CmsBannerFullWidth_v4
            }
            sections {
              data {
                id
                attributes {
                  ...CmsSection_v4
                  cards {
                    ...CmsCardFullWidth_v4
                  }
                  images {
                    ...CmsImageFullWidth_v4
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default LocateUs;
